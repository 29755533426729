import React from "react";
import Button from "../button";
import WhatsAppIcon from "../../icons/WhatsappIcon";
import { Typo } from "../typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { formatWhatsApp } from "../../../util/helper";
type Props = {
  whatsApp?: string;
};

const WhatsAppButton: React.FC<Props> = (props) => {
  const { whatsApp } = props;

  const sendWhatsApp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (whatsApp) {
      window.open(`whatsapp://send?phone=${formatWhatsApp(whatsApp)}`);
    }
  };
  return (
    <>
      <Button
        size="sxtra-small"
        rounded="rounded-lg"
        disabled={!whatsApp}
        theme="stroke-primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          sendWhatsApp(event)
        }
        icon={<WhatsAppIcon className="h-5 w-5" />}
      >
        <Typo.heading4>
          <FormattedMessage
            id={TRANSLATION.WHATSAPP.id}
            defaultMessage={TRANSLATION.WHATSAPP.defaultMessage}
          />
        </Typo.heading4>
      </Button>
    </>
  );
};

export default WhatsAppButton;
