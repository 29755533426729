type Props = {
  className?: string;
};
const GoogleIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-4 w-4 ${className}`}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5001 8.18156C16.5001 7.63612 16.4546 7.09271 16.3595 6.55493H8.66113V9.63534H13.0698C12.8876 10.6292 12.2993 11.5083 11.4399 12.0655V14.0667H14.0719C15.6128 12.6769 16.5001 10.6175 16.5001 8.18156Z"
        fill="#4285F4"
      />
      <path
        d="M8.66132 15.9999C10.8638 15.9999 12.7195 15.2915 14.0721 14.0686L11.4401 12.0674C10.7073 12.5546 9.76453 12.8322 8.66132 12.8322C6.53233 12.8322 4.72604 11.425 4.07844 9.53052H1.36914V11.5918C2.75545 14.2958 5.57574 15.9999 8.66132 15.9999Z"
        fill="#34A853"
      />
      <path
        d="M4.08025 9.53043C3.73768 8.53862 3.73768 7.46132 4.08025 6.46763V4.4082H1.36903C0.210324 6.66938 0.210324 9.33244 1.36903 11.5938L4.08025 9.53043Z"
        fill="#FBBC04"
      />
      <path
        d="M8.66132 3.16808C9.82592 3.15061 10.9489 3.57965 11.7925 4.36769L14.1236 2.08501C12.6462 0.726394 10.6895 -0.0209251 8.66132 0.000446046C5.57574 0.000446046 2.75353 1.70651 1.36914 4.40842L4.08036 6.46973C4.72604 4.57334 6.53233 3.16808 8.66132 3.16808Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
