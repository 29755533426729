import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Typo } from "../../components/shared/typo";
type Props = {
  className?: string;
  title: MessageDescriptor;
  description: MessageDescriptor;
  items?: MessageDescriptor[];
};
const PrivacyPolicyItem: React.FC<Props> = (props) => {
  const { className, title, description, items } = props;
  return (
    <div className="w-full">
      <Typo.heading1 className=" text-primary-700" fontWeight="font-bold">
        <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
      </Typo.heading1>
      <Typo.heading3 className="text-gray-900 mt-4">
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        />
      </Typo.heading3>
      {items && items?.length > 0 && (
        <ul role="list" className="mt-6 max-w-xl space-y-5 text-gray-900">
          {items?.map((item, index) => {
            return (
              <li key={index} className="flex gap-x-3 items-center ">
                <div className="h-2 w-2 rounded-full bg-gray-900"></div>
                <span>
                  <FormattedMessage
                    id={item.id}
                    defaultMessage={item.defaultMessage}
                  />
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default PrivacyPolicyItem;
