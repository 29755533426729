import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import NotFound from "./pages/shared/404";
import Dashboard from "./pages/dashboard/home";
import { PublicLayout } from "./layout/publicLayout";
import { DashboardLayout } from "./layout/dashboardLayout";
import { AuthProvider } from "./context/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./lib/store";
import Categories from "./pages/dashboard/categories";
import SubCategories from "./pages/dashboard/subCategories";
import Preference from "./pages/dashboard/profile/preference";
import Settings from "./pages/dashboard/profile/settings";
import ChangePassword from "./pages/dashboard/profile/changePassword";
import Users from "./pages/dashboard/users";
import NewListing from "./pages/dashboard/listings/newListing";
import Provinces from "./pages/dashboard/provinces";
import NewArticle from "./pages/dashboard/articles/newArticle";
import ArticleCategory from "./pages/dashboard/articles/articleCategory";
import Articles from "./pages/dashboard/articles";
import Countries from "./pages/dashboard/countries";
import Home from "./pages/website/home";
import Agencies from "./pages/website/agencies";
import DashboardListingDetails from "./pages/dashboard/listings/[slug]";
import AgencyDetail from "./pages/website/agencies/[id]";
import UserDetails from "./pages/dashboard/users/[id]";
import DashboardListings from "./pages/dashboard/listings/DashboardListings";
import Listings from "./pages/website/listings";
import ListingDetails from "./pages/website/listings/[slug]";
import MyListings from "./pages/dashboard/listings/my-listings";
import Favorites from "./pages/dashboard/favorites";
import EditListing from "./pages/dashboard/listings/edit-listing/[slug]";
import PublicArticle from "./pages/website/articles";
import ArticleDetails from "./pages/website/articles/[id]";
import EditArticle from "./pages/dashboard/articles/[id]";
import CompeleteProfile from "./pages/dashboard/profile/compeleteProfile";
import AboutUs from "./pages/website/about";
import ContactUs from "./pages/website/contact";
import PrivacyPolicy from "./pages/website/privacyPolicy";
import { NotificationProvider } from "./context/notification";
import Messages from "./pages/dashboard/messages.tsx";
import AboutUsMobile from "./pages/mobile/about";
import ContactMobile from "./pages/mobile/contact";
import PrivacyPolicyMobile from "./pages/mobile/privacyPolicy";
import EmailConfirmation from "./pages/website/user/email-confirmation";
import ResetPassword from "./pages/website/user/reset-paasowrd";
import ForgotPasswordVerification from "./pages/website/user/forgot-password-verification";
import MobileArticles from "./pages/mobile/articles";
import MobileArticleDetails from "./pages/mobile/articles/[id]";
import DeleteProfile from "./pages/dashboard/profile/deleteProfile";
function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AuthProvider>
              <NotificationProvider>
                <Routes>
                  <Route
                    element={<AboutUsMobile />}
                    path={ROUTES.MOBILE_ABOUT_US}
                  />
                  <Route
                    element={<ContactMobile />}
                    path={ROUTES.MOBILE_CONTACT_US}
                  />
                  <Route
                    element={<MobileArticles />}
                    path={ROUTES.MOBILE_ARTICLES}
                  />
                  <Route
                    element={<MobileArticleDetails />}
                    path={ROUTES.MOBILE_ARTICLES_DETAILS}
                  />
                  <Route
                    element={<PrivacyPolicyMobile />}
                    path={ROUTES.MOBILE_PRIVACY_POLICY}
                  />
                  <Route element={<PublicLayout />}>
                    <Route path={ROUTES.HOME_ROUTE} element={<Home />} />
                    <Route
                      path={ROUTES.AGENCIES_ROUTE}
                      element={<Agencies />}
                    />
                    <Route
                      path={ROUTES.AGENCY_DETAILS_ROUTE}
                      element={<AgencyDetail />}
                    />

                    <Route
                      path={ROUTES.LISTINGS_ROUTE}
                      element={<Listings />}
                    />
                    <Route
                      path={ROUTES.LISTING_DETAILS}
                      element={<ListingDetails />}
                    />

                    <Route
                      path={ROUTES.ARTICLES_ROUTE}
                      element={<PublicArticle />}
                    />
                    <Route
                      path={ROUTES.ARTICLE_DETAILS_ROUTE}
                      element={<ArticleDetails />}
                    />
                    <Route path={ROUTES.ABOUT_ROUTE} element={<AboutUs />} />
                    <Route
                      path={ROUTES.CONTACT_ROUTE}
                      element={<ContactUs />}
                    />
                    <Route
                      path={ROUTES.PRIVACY_POLICY_ROUTE}
                      element={<PrivacyPolicy />}
                    />
                    <Route
                      path={ROUTES.EMAIL_CONFIRMATION_ROUTE}
                      element={<EmailConfirmation />}
                    />
                    <Route
                      path={ROUTES.FORGOT_PASSWORD_VERIFICATION_ROUTE}
                      element={<ForgotPasswordVerification />}
                    />
                    <Route
                      path={ROUTES.RESET_PASSWORD_ROUTE}
                      element={<ResetPassword />}
                    />
                    <Route
                      path={ROUTES.NOT_FOUND_ROUTE}
                      element={<NotFound />}
                    />
                  </Route>
                  <Route element={<DashboardLayout />}>
                    <Route
                      path={ROUTES.DASHBOARD_ROUTE}
                      element={<Dashboard />}
                    />
                    <Route
                      path={ROUTES.CATEGORIES_ROUTE}
                      element={<Categories />}
                    />
                    <Route
                      path={ROUTES.SUB_CATEGORIES_ROUTE}
                      element={<SubCategories />}
                    />
                    <Route
                      path={ROUTES.PREFERENCE_ROUTE}
                      element={<Preference />}
                    />
                    <Route
                      path={ROUTES.SETTINGS_ROUTE}
                      element={<Settings />}
                    />
                    <Route
                      path={ROUTES.CHANGE_PASSWORD_ROUTE}
                      element={<ChangePassword />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_USERS_ROUTE}
                      element={<Users />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_USERS_DETAILS_ROUTE}
                      element={<UserDetails />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_LISTINGS_ROUTE}
                      element={<DashboardListings />}
                    />
                    <Route
                      path={ROUTES.MY_LISTINGS_ROUTE}
                      element={<MyListings />}
                    />
                    <Route
                      path={ROUTES.NEW_LISTING_ROUTE}
                      element={<NewListing />}
                    />
                    <Route
                      path={ROUTES.COMPELETE_PROFILE_ROUTE}
                      element={<CompeleteProfile />}
                    />
                    <Route
                      path={ROUTES.EDIT_LISTING_DETAILS_ROUTE}
                      element={<EditListing />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_PROVINCES_ROUTE}
                      element={<Provinces />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_COUNTRIES_ROUTE}
                      element={<Countries />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_ARTICLES_ROUTE}
                      element={<Articles />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_NEW_ARTICLE_ROUTE}
                      element={<NewArticle />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_EDIT_ARTICLE_ROUTE}
                      element={<EditArticle />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_ARTICLE_CATEGORY_ROUTE}
                      element={<ArticleCategory />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_LISTING_DETAILS}
                      element={<DashboardListingDetails />}
                    />
                    <Route
                      path={ROUTES.FAVORITES_ROUTE}
                      element={<Favorites />}
                    />
                    <Route
                      path={ROUTES.DASHBOARD_MESSAGES_ROUTE}
                      element={<Messages />}
                    />
                    <Route
                      path={ROUTES.DELETE_PROFILE}
                      element={<DeleteProfile />}
                    />
                  </Route>
                </Routes>
              </NotificationProvider>
            </AuthProvider>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
