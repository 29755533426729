import { FC } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import logo from "../../src/assets/image/logo.svg";
import Image from "../components/shared/Image";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../lib/reducers/modal.slice";
import { handelInputMap } from "../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../static/translation";
import InputField from "../components/shared/InputField";
import { useHandelForgotPassword } from "../hooks/forgotPassword/useHandelForgotPassword";
import LeftArrowIcon from "../components/icons/LeftArrowIcon";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
};

const ForgoatPasswordModal: FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { register, errors, handleSubmit, onSubmit, loading } =
    useHandelForgotPassword();

  return (
    <Modal
      className="max-w-md"
      open={open}
      onClose={() => onClose(false)}
      showCloseBtn={false}
    >
      <div className="w-full relative">
        <div className="absolute start-0">
          <Button
            onClick={() => {
              dispatch(hideModal("forgotPassword"));
              dispatch(showModal("login"));
            }}
            icon={<LeftArrowIcon className="h-5 text-gray-700 rotate-180" />}
          ></Button>
        </div>
        <div className=" items-center">
          <Image className="h-14 w-14 mx-auto" src={logo} alt="logo" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.FORGOT_PASSWORD_TITLE.id}
              defaultMessage={TRANSLATION.FORGOT_PASSWORD_TITLE.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.FORGOT_PASSWORD_DESCRIPTION.id}
              defaultMessage={
                TRANSLATION.FORGOT_PASSWORD_DESCRIPTION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full mt-3 mb-7">
            <InputField
              name="email"
              label={handelInputMap(intl, "email")}
              placeholder={handelInputMap(intl, "email_placeholder")}
              type="email"
              register={register}
              errors={errors?.email}
              required={true}
            />
          </div>
          <div className=" flex flex-col space-y-2 pt-6">
            <Button
              loading={loading}
              size="medium"
              type="submit"
              theme="primary"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.SEND.id}
                  defaultMessage={TRANSLATION.SEND.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ForgoatPasswordModal;
