type Props = {
  className?: string;
  type: "primary" | "secondary";
};
const AHLogo = ({ className, type }: Props) => {
  return (
    <svg
      id="Layer_1"
      fill="#06df70"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 510.13 449.08"
      className={className}
    >
      <path
        className="cls-1 fill-primary-600"
        d="M256.53,0s54.55,55.84,119.5,119l-40.26,53.85-79.23-108.36s-79.23,124.32-183.79,180.16L0,170.86S148.72,127.64,256.53,0Z"
      />
      <path
        className="cls-1 fill-primary-600"
        d="M253.6,449.08s-54.55-55.84-119.5-119l40.26-53.85,79.23,108.36s79.23-124.32,183.79-180.16l72.74,73.79s-148.72,43.21-256.53,170.86Z"
      />
      <rect
        className="fill-primary-600"
        x="225.19"
        y="189.51"
        width="25.65"
        height="28.25"
      />
      <rect
        className="fill-primary-600"
        x="260.26"
        y="189.51"
        width="25.65"
        height="28.25"
      />
      <rect
        className="fill-primary-600"
        x="225.19"
        y="227.08"
        width="25.65"
        height="28.25"
      />
      <rect
        className="fill-primary-600"
        x="260.26"
        y="227.08"
        width="25.65"
        height="28.25"
      />
    </svg>
  );
};

export default AHLogo;
