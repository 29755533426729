import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from "../icons/CloseIcon";
import { TRANSLATION } from "../../static/translation";
import { Typo } from "../shared/typo";
import { FormattedMessage } from "react-intl";
import Button from "../shared/button";
import LogoutIcon from "../icons/LogoutIcon";
import SidebarNav from "./sidebar/SidebarNav";
import { Navigation } from "../../model/Navigation";
import { ROUTES } from "../../constants/routes";
import AHLogo from "../icons/AHLogo";
import AHNameColumn from "../icons/AHNameColumn";

type Props = {
  title?: string;
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  navigations: Navigation[];
  auth: any;
  location: any;
  direction: string;
};
const Header: React.FC<Props> = (props) => {
  const {
    sidebarOpen,
    setSidebarOpen,
    navigations,
    auth,
    location,
    direction,
  } = props;
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 justify-start flex" dir="rtl">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="-translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="-translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="relative ml-16 flex w-full bg-white max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-full top-0 flex w-16 justify-center pt-3">
                  <button
                    type="button"
                    className="pt-2"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <CloseIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
                <div className="h-16 w-full shrink-0">
                  <Button to={ROUTES.HOME_ROUTE}>
                    <div className="flex items-center justify-start gap-x-3 w-full pt-2">
                      <div>
                        <AHLogo
                          className="h-10 text-primary-600"
                          type="primary"
                        />
                      </div>
                      <div>
                        <AHNameColumn className="h-8 text-secondary-600" />
                      </div>
                    </div>
                  </Button>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigations
                          .filter((item: any) =>
                            item.roles?.includes(Number(auth?.user?.roleId))
                          )
                          .map((item: any) => (
                            <SidebarNav
                              current={location.pathname}
                              key={item.name}
                              nav={item}
                              direction={direction}
                            />
                          ))}
                      </ul>
                    </li>
                    <li className="relative mt-auto ">
                      <div className="w-full px-2 relative ">
                        <Button
                          onClick={() => auth.logout()}
                          theme=""
                          className={`flex items-center py-2 ps-6 text-gray-900`}
                        >
                          <div className="ml-3">
                            <LogoutIcon />
                          </div>
                          <Typo.body4>
                            <FormattedMessage
                              id={TRANSLATION.LOGOUT.id}
                              defaultMessage={TRANSLATION.LOGOUT.defaultMessage}
                            />
                          </Typo.body4>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Header;
