import AfFlag from "../components/flags/AfFlag";
import AddHomeIcon from "../components/icons/AddHomeIcon";
import CategoryIcon from "../components/icons/CategoryIcon";
import DocumentIcon from "../components/icons/DocumentIcon";
import GlobeIcon from "../components/icons/GlobeIcon";
import Grid5Icon from "../components/icons/Grid5Icon";
import HeartIcon from "../components/icons/HeartIcon";
import Home2Icon from "../components/icons/Home2Icon";
import ProfileIcon from "../components/icons/ProfileIcon";
import SmsIcon from "../components/icons/SmsIcon";
import UserEditIcon from "../components/icons/UserEditIcon";
import { ROUTES } from "../constants/routes";
import { DropdownOptionModel } from "../model/DropdownOption";
import { Navigation } from "../model/Navigation";
import { IntlShape } from "react-intl";
import { TRANSLATION } from "./translation";
import USFlag from "../components/flags/UsFlag";
import { ListingState } from "./enum";
import Home3Icon from "../components/icons/Home3Icon";

export const DEBOUNCE = 1000;

export const getNearbyTabs = (intl: IntlShape) => {
  const tabs: DropdownOptionModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_RESTAURANT.id,
        defaultMessage: TRANSLATION.NEARBY_RESTAURANT.defaultMessage,
      }),
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_BUS.id,
        defaultMessage: TRANSLATION.NEARBY_BUS.defaultMessage,
      }),
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_LIBRARY.id,
        defaultMessage: TRANSLATION.NEARBY_LIBRARY.defaultMessage,
      }),
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_HOSPITAL.id,
        defaultMessage: TRANSLATION.NEARBY_HOSPITAL.defaultMessage,
      }),
    },
    {
      id: 5,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_STORE.id,
        defaultMessage: TRANSLATION.NEARBY_STORE.defaultMessage,
      }),
    },
    {
      id: 6,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_SCHOOL.id,
        defaultMessage: TRANSLATION.NEARBY_SCHOOL.defaultMessage,
      }),
    },
    {
      id: 7,
      title: intl.formatMessage({
        id: TRANSLATION.NEARBY_MOSQUE.id,
        defaultMessage: TRANSLATION.NEARBY_MOSQUE.defaultMessage,
      }),
    },
  ];
  return tabs;
};

export const getListingStates = (intl: IntlShape) => {
  const states: DropdownOptionModel[] = [
    {
      id: ListingState.PENDING,
      title: intl.formatMessage({
        id: TRANSLATION.PENDING.id,
        defaultMessage: TRANSLATION.PENDING.defaultMessage,
      }),
    },
    {
      id: ListingState.ACTIVE,
      title: intl.formatMessage({
        id: TRANSLATION.ACTIVE.id,
        defaultMessage: TRANSLATION.ACTIVE.defaultMessage,
      }),
    },
    {
      id: ListingState.EXPIRED,
      title: intl.formatMessage({
        id: TRANSLATION.EXPIRED.id,
        defaultMessage: TRANSLATION.EXPIRED.defaultMessage,
      }),
    },
    {
      id: ListingState.INACTIVE,
      title: intl.formatMessage({
        id: TRANSLATION.INACTIVE.id,
        defaultMessage: TRANSLATION.INACTIVE.defaultMessage,
      }),
    },
    {
      id: ListingState.REJECTED,
      title: intl.formatMessage({
        id: TRANSLATION.REJECTED.id,
        defaultMessage: TRANSLATION.REJECTED.defaultMessage,
      }),
    },
    {
      id: ListingState.DELETED,
      title: intl.formatMessage({
        id: TRANSLATION.DELETED.id,
        defaultMessage: TRANSLATION.DELETED.defaultMessage,
      }),
    },
    {
      id: ListingState.EDITED,
      title: intl.formatMessage({
        id: TRANSLATION.EDITED.id,
        defaultMessage: TRANSLATION.EDITED.defaultMessage,
      }),
    },
  ];
  return states;
};

export const getMenus = (intl: IntlShape) => {
  const menu: Navigation[] = [
    {
      id: 1,
      name: intl.formatMessage({
        id: TRANSLATION.HOME.id,
        defaultMessage: TRANSLATION.HOME.defaultMessage,
      }),
      href: ROUTES.HOME_ROUTE,
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: TRANSLATION.LISTINGS.id,
        defaultMessage: TRANSLATION.LISTINGS.defaultMessage,
      }),
      href: ROUTES.LISTINGS_ROUTE,
    },
    // {
    //   id: "sell",
    //   name: intl.formatMessage({
    //     id: TRANSLATION.SELL.id,
    //     defaultMessage: TRANSLATION.SELL.defaultMessage,
    //   }),
    //   href: "",
    // },
    // {
    //   id: "rent",
    //   name: intl.formatMessage({
    //     id: TRANSLATION.RENT.id,
    //     defaultMessage: TRANSLATION.RENT.defaultMessage,
    //   }),
    //   href: "",
    // },
    // {
    //   id: "mortgage",
    //   name: intl.formatMessage({
    //     id: TRANSLATION.MORTGAGE.id,
    //     defaultMessage: TRANSLATION.MORTGAGE.defaultMessage,
    //   }),
    //   href: "",
    // },
    {
      id: 5,
      name: intl.formatMessage({
        id: TRANSLATION.AGENCY.id,
        defaultMessage: TRANSLATION.AGENCY.defaultMessage,
      }),
      href: ROUTES.AGENCIES_ROUTE,
    },
    {
      id: 6,
      name: intl.formatMessage({
        id: TRANSLATION.ARTICLES.id,
        defaultMessage: TRANSLATION.ARTICLES.defaultMessage,
      }),
      href: ROUTES.ARTICLES_ROUTE,
    },
    {
      id: 7,
      name: intl.formatMessage({
        id: TRANSLATION.ABOUT_US.id,
        defaultMessage: TRANSLATION.ABOUT_US.defaultMessage,
      }),
      href: ROUTES.ABOUT_ROUTE,
    },
    {
      id: 8,
      name: intl.formatMessage({
        id: TRANSLATION.CONTACT_US.id,
        defaultMessage: TRANSLATION.CONTACT_US.defaultMessage,
      }),
      href: ROUTES.CONTACT_ROUTE,
    },
  ];
  return menu;
};

export const getNavigations = (intl: IntlShape) => {
  const navigation: Navigation[] = [
    {
      name: intl.formatMessage({
        id: TRANSLATION.HOME.id,
        defaultMessage: TRANSLATION.HOME.defaultMessage,
      }),
      href: ROUTES.HOME_ROUTE,
      icon: <Home3Icon />,
      items: [],
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.MAIN_PAGE.id,
        defaultMessage: TRANSLATION.MAIN_PAGE.defaultMessage,
      }),
      href: ROUTES.DASHBOARD_ROUTE,
      icon: <Grid5Icon />,
      items: [],
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.MY_LISTINGS.id,
        defaultMessage: TRANSLATION.MY_LISTINGS.defaultMessage,
      }),
      href: ROUTES.MY_LISTINGS_ROUTE,
      icon: <Home2Icon />,
      items: [],
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.ADD_NEW_LISTING.id,
        defaultMessage: TRANSLATION.ADD_NEW_LISTING.defaultMessage,
      }),
      href: ROUTES.NEW_LISTING_ROUTE,
      icon: <AddHomeIcon />,
      items: [],
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.FAVORITES.id,
        defaultMessage: TRANSLATION.FAVORITES.defaultMessage,
      }),
      href: ROUTES.FAVORITES_ROUTE,
      icon: <HeartIcon className="h-5 w-5" />,
      items: [],
      roles: [1, 2],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.PROFILE.id,
        defaultMessage: TRANSLATION.PROFILE.defaultMessage,
      }),
      href: "",
      icon: <UserEditIcon className="h-5 w-5" />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.SETTINGS.id,
            defaultMessage: TRANSLATION.SETTINGS.defaultMessage,
          }),
          href: ROUTES.SETTINGS_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.DELETE_PROFILE_TITLE.id,
            defaultMessage: TRANSLATION.DELETE_PROFILE_TITLE.defaultMessage,
          }),
          href: ROUTES.DELETE_PROFILE,
        },
        // {
        //   name: intl.formatMessage({
        //     id: TRANSLATION.CHANGE_PASSWORD.id,
        //     defaultMessage: TRANSLATION.CHANGE_PASSWORD.defaultMessage,
        //   }),
        //   href: ROUTES.CHANGE_PASSWORD_ROUTE,
        // },
        // {
        //   name: intl.formatMessage({
        //     id: TRANSLATION.PREFERENCE.id,
        //     defaultMessage: TRANSLATION.PREFERENCE.defaultMessage,
        //   }),
        //   href: ROUTES.PREFERENCE_ROUTE,
        // },
      ],
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.CATEGORIES.id,
        defaultMessage: TRANSLATION.CATEGORIES.defaultMessage,
      }),
      href: "",
      icon: <CategoryIcon className="h-5 w-5" />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.MAIN_CATEGORY.id,
            defaultMessage: TRANSLATION.MAIN_CATEGORY.defaultMessage,
          }),
          href: ROUTES.CATEGORIES_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.SUB_CATEGORY.id,
            defaultMessage: TRANSLATION.SUB_CATEGORY.defaultMessage,
          }),
          href: ROUTES.SUB_CATEGORIES_ROUTE,
        },
      ],
      roles: [3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.USER.id,
        defaultMessage: TRANSLATION.USER.defaultMessage,
      }),
      href: "",
      icon: <ProfileIcon className="h-6 w-6" />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.USERS.id,
            defaultMessage: TRANSLATION.USERS.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_USERS_ROUTE,
        },
      ],
      roles: [4],
    },

    {
      name: intl.formatMessage({
        id: TRANSLATION.LISTING_MANAGEMENT.id,
        defaultMessage: TRANSLATION.LISTING_MANAGEMENT.defaultMessage,
      }),
      href: ROUTES.DASHBOARD_LISTINGS_ROUTE,
      icon: <Home2Icon />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.LISTINGS.id,
            defaultMessage: TRANSLATION.LISTINGS.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_LISTINGS_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.REGISTER_LISTING.id,
            defaultMessage: TRANSLATION.REGISTER_LISTING.defaultMessage,
          }),
          href: ROUTES.NEW_LISTING_ROUTE,
        },
      ],
      roles: [3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.MANAGE_MESSAGES.id,
        defaultMessage: TRANSLATION.MANAGE_MESSAGES.defaultMessage,
      }),
      href: ROUTES.DASHBOARD_MESSAGES_ROUTE,
      icon: <SmsIcon />,
      roles: [1, 2, 3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.PROVINCE_AND_COUNTRY.id,
        defaultMessage: TRANSLATION.PROVINCE_AND_COUNTRY.defaultMessage,
      }),
      href: "",
      icon: <GlobeIcon className="h-5 w-5" />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.COUNTRY.id,
            defaultMessage: TRANSLATION.COUNTRY.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_COUNTRIES_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.PROVINCE.id,
            defaultMessage: TRANSLATION.PROVINCE.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_PROVINCES_ROUTE,
        },
      ],
      roles: [3, 4],
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.ARTICLES.id,
        defaultMessage: TRANSLATION.ARTICLES.defaultMessage,
      }),
      href: "",
      icon: <DocumentIcon className="h-5 w-5" />,
      items: [
        {
          name: intl.formatMessage({
            id: TRANSLATION.ARTICLE_CATEGORY.id,
            defaultMessage: TRANSLATION.ARTICLE_CATEGORY.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_ARTICLE_CATEGORY_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.ARTICLES.id,
            defaultMessage: TRANSLATION.ARTICLES.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_ARTICLES_ROUTE,
        },
        {
          name: intl.formatMessage({
            id: TRANSLATION.NEW_ARTICLE.id,
            defaultMessage: TRANSLATION.NEW_ARTICLE.defaultMessage,
          }),
          href: ROUTES.DASHBOARD_NEW_ARTICLE_ROUTE,
        },
      ],
      roles: [3, 4],
    },
  ];
  return navigation;
};

export const getLanguages = (intl: IntlShape) => {
  const languages: DropdownOptionModel[] = [
    {
      id: "fa",
      title: intl.formatMessage({
        id: TRANSLATION.DARI.id,
        defaultMessage: TRANSLATION.DARI.defaultMessage,
      }),
      icon: <AfFlag className="h-4 rounded-sm" />,
    },
    {
      id: "ps",
      title: intl.formatMessage({
        id: TRANSLATION.PASHTO.id,
        defaultMessage: TRANSLATION.PASHTO.defaultMessage,
      }),
      icon: <AfFlag className="h-4 rounded-sm" />,
    },
    {
      id: "en",
      title: intl.formatMessage({
        id: TRANSLATION.ENGLISH.id,
        defaultMessage: TRANSLATION.ENGLISH.defaultMessage,
      }),
      icon: <USFlag className="h-4 rounded-sm" />,
    },
  ];
  return languages;
};

export enum ALERTS {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
}

export const countries: DropdownOptionModel[] = [
  { id: "af", title: "افغانستان" },
];

export const getRoles = (intl: IntlShape) => {
  const roles: DropdownOptionModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATION.ROLE_INDIVIDUAL.id,
        defaultMessage: TRANSLATION.ROLE_INDIVIDUAL.defaultMessage,
      }),
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATION.ROLE_AGENCY.id,
        defaultMessage: TRANSLATION.ROLE_AGENCY.defaultMessage,
      }),
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATION.ROLE_ADMIN.id,
        defaultMessage: TRANSLATION.ROLE_ADMIN.defaultMessage,
      }),
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATION.ROLE_SUPER_ADMIN.id,
        defaultMessage: TRANSLATION.ROLE_SUPER_ADMIN.defaultMessage,
      }),
    },
  ];
  return roles;
};

export const getUserStates = (intl: IntlShape) => {
  const userStates: DropdownOptionModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATION.CONFIRMED.id,
        defaultMessage: TRANSLATION.CONFIRMED.defaultMessage,
      }),
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATION.DOES_NOT_CONFIRMED.id,
        defaultMessage: TRANSLATION.DOES_NOT_CONFIRMED.defaultMessage,
      }),
    },
  ];
  return userStates;
};

export const getFeaturesTab = (intl: IntlShape) => {
  const featureTabs: DropdownOptionModel[] = [
    {
      id: "1",
      title: intl.formatMessage({
        id: TRANSLATION.FEATURE_TAB_MAIN_FEATURE.id,
        defaultMessage: TRANSLATION.FEATURE_TAB_MAIN_FEATURE.defaultMessage,
      }),
    },
    {
      id: "2",
      title: intl.formatMessage({
        id: TRANSLATION.FEATURE_TAB_POSSIBILITY.id,
        defaultMessage: TRANSLATION.FEATURE_TAB_POSSIBILITY.defaultMessage,
      }),
    },
    {
      id: "3",
      title: intl.formatMessage({
        id: TRANSLATION.FEATURE_TAB_NEARBY_LOCATIONS.id,
        defaultMessage: TRANSLATION.FEATURE_TAB_NEARBY_LOCATIONS.defaultMessage,
      }),
    },
    {
      id: "4",
      title: intl.formatMessage({
        id: TRANSLATION.FEATURE_TAB_POINTS.id,
        defaultMessage: TRANSLATION.FEATURE_TAB_POINTS.defaultMessage,
      }),
    },
    {
      id: "5",
      title: intl.formatMessage({
        id: TRANSLATION.FEATURE_TAB_RECREATION.id,
        defaultMessage: TRANSLATION.FEATURE_TAB_RECREATION.defaultMessage,
      }),
    },
  ];
  return featureTabs;
};

export const getNomas = (intl: IntlShape) => {
  const nomas: DropdownOptionModel[] = [
    {
      id: "ceramic",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_CERAMIC.id,
        defaultMessage: TRANSLATION.NOMA_CERAMIC.defaultMessage,
      }),
    },
    {
      id: "travertine",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_TRAVERTINE.id,
        defaultMessage: TRANSLATION.NOMA_TRAVERTINE.defaultMessage,
      }),
    },
    {
      id: "cement",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_CEMENT.id,
        defaultMessage: TRANSLATION.NOMA_CEMENT.defaultMessage,
      }),
    },
    {
      id: "glass",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_GLASS.id,
        defaultMessage: TRANSLATION.NOMA_GLASS.defaultMessage,
      }),
    },
    {
      id: "alkoban",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_ALKOBAN.id,
        defaultMessage: TRANSLATION.NOMA_ALKOBAN.defaultMessage,
      }),
    },
    {
      id: "other",
      title: intl.formatMessage({
        id: TRANSLATION.NOMA_OTHER.id,
        defaultMessage: TRANSLATION.NOMA_OTHER.defaultMessage,
      }),
    },
  ];
  return nomas;
};

export const getFloors = (intl: IntlShape) => {
  const floors: DropdownOptionModel[] = [
    {
      id: "cement",
      title: intl.formatMessage({
        id: TRANSLATION.FLOOR_CEMENT.id,
        defaultMessage: TRANSLATION.FLOOR_CEMENT.defaultMessage,
      }),
    },
    {
      id: "mosaic",
      title: intl.formatMessage({
        id: TRANSLATION.FLOOR_MOSAIC.id,
        defaultMessage: TRANSLATION.FLOOR_MOSAIC.defaultMessage,
      }),
    },
    {
      id: "ceramic",
      title: intl.formatMessage({
        id: TRANSLATION.FLOOR_CERAMIC.id,
        defaultMessage: TRANSLATION.FLOOR_CERAMIC.defaultMessage,
      }),
    },
    {
      id: "wood",
      title: intl.formatMessage({
        id: TRANSLATION.FLOOR_WOOD.id,
        defaultMessage: TRANSLATION.FLOOR_WOOD.defaultMessage,
      }),
    },
    {
      id: "other",
      title: intl.formatMessage({
        id: TRANSLATION.FLOOR_OTHER.id,
        defaultMessage: TRANSLATION.FLOOR_OTHER.defaultMessage,
      }),
    },
  ];
  return floors;
};

export const getElectricityBackups = (intl: IntlShape) => {
  const electricityBackups: DropdownOptionModel[] = [
    {
      id: "nothing",
      title: intl.formatMessage({
        id: TRANSLATION.ELECTRICITY_NOTHING.id,
        defaultMessage: TRANSLATION.ELECTRICITY_NOTHING.defaultMessage,
      }),
    },
    {
      id: "solar",
      title: intl.formatMessage({
        id: TRANSLATION.ELECTRICITY_SOLAR.id,
        defaultMessage: TRANSLATION.ELECTRICITY_SOLAR.defaultMessage,
      }),
    },
    {
      id: "generator",
      title: intl.formatMessage({
        id: TRANSLATION.ELECTRICITY_GENERATOR.id,
        defaultMessage: TRANSLATION.ELECTRICITY_GENERATOR.defaultMessage,
      }),
    },
    {
      id: "ups",
      title: intl.formatMessage({
        id: TRANSLATION.ELECTRICITY_UPS.id,
        defaultMessage: TRANSLATION.ELECTRICITY_UPS.defaultMessage,
      }),
    },
    {
      id: "other",
      title: intl.formatMessage({
        id: TRANSLATION.ELECTRICITY_OTHER.id,
        defaultMessage: TRANSLATION.ELECTRICITY_OTHER.defaultMessage,
      }),
    },
  ];
  return electricityBackups;
};

export const getBuildYears = () => {
  const currentYear = new Date().getFullYear();
  const years: DropdownOptionModel[] = [];
  Array.from({ length: 30 }).forEach((item, index) => {
    years.push({
      id: currentYear - index,
      title: `${currentYear - index}`,
    });
  });
  return years;
};

export const getGolas = (intl: IntlShape) => {
  const goals: DropdownOptionModel[] = [
    {
      id: "sell",
      title: intl.formatMessage({
        id: TRANSLATION.SELL.id,
        defaultMessage: TRANSLATION.SELL.defaultMessage,
      }),
    },
    {
      id: "rent",
      title: intl.formatMessage({
        id: TRANSLATION.RENT.id,
        defaultMessage: TRANSLATION.RENT.defaultMessage,
      }),
    },
    {
      id: "mortgage",
      title: intl.formatMessage({
        id: TRANSLATION.MORTGAGE.id,
        defaultMessage: TRANSLATION.MORTGAGE.defaultMessage,
      }),
    },
  ];

  return goals;
};

export const getDocuments = (intl: IntlShape) => {
  const documents: DropdownOptionModel[] = [
    {
      id: "",
      title: intl.formatMessage({
        id: TRANSLATION.DOCUMENT_NO_MATTER.id,
        defaultMessage: TRANSLATION.DOCUMENT_NO_MATTER.defaultMessage,
      }),
    },
    {
      id: "sharia",
      title: intl.formatMessage({
        id: TRANSLATION.DOCUMENT_SHARIA.id,
        defaultMessage: TRANSLATION.DOCUMENT_SHARIA.defaultMessage,
      }),
    },
    {
      id: "customary",
      title: intl.formatMessage({
        id: TRANSLATION.DOCUMENT_CUSTOMARY.id,
        defaultMessage: TRANSLATION.DOCUMENT_CUSTOMARY.defaultMessage,
      }),
    },
    {
      id: "property",
      title: intl.formatMessage({
        id: TRANSLATION.DOCUMENT_PROPERTY.id,
        defaultMessage: TRANSLATION.DOCUMENT_PROPERTY.defaultMessage,
      }),
    },
  ];
  return documents;
};

export const getCurrenciesWithLabel = (intl: IntlShape) => {
  const currencies: DropdownOptionModel[] = [
    {
      id: "",
      title: intl.formatMessage({
        id: TRANSLATION.CURRENCY.id,
        defaultMessage: TRANSLATION.CURRENCY.defaultMessage,
      }),
    },
    {
      id: "af",
      title: intl.formatMessage({
        id: TRANSLATION.AF_CURRENCY.id,
        defaultMessage: TRANSLATION.AF_CURRENCY.defaultMessage,
      }),
    },
    {
      id: "us",
      title: intl.formatMessage({
        id: TRANSLATION.US_CURRENCY.id,
        defaultMessage: TRANSLATION.US_CURRENCY.defaultMessage,
      }),
    },
  ];
  return currencies;
};

export const getCurrencies = (intl: IntlShape) => {
  const currencies: DropdownOptionModel[] = [
    {
      id: "af",
      title: intl.formatMessage({
        id: TRANSLATION.AF_CURRENCY.id,
        defaultMessage: TRANSLATION.AF_CURRENCY.defaultMessage,
      }),
    },
    {
      id: "us",
      title: intl.formatMessage({
        id: TRANSLATION.US_CURRENCY.id,
        defaultMessage: TRANSLATION.US_CURRENCY.defaultMessage,
      }),
    },
  ];
  return currencies;
};

export const getUnitsWithLabel = (intl: IntlShape) => {
  const currencies: DropdownOptionModel[] = [
    {
      id: "",
      title: intl.formatMessage({
        id: TRANSLATION.UNIT.id,
        defaultMessage: TRANSLATION.UNIT.defaultMessage,
      }),
    },
    {
      id: "squareMters",
      title: intl.formatMessage({
        id: TRANSLATION.SQUARE_METERS.id,
        defaultMessage: TRANSLATION.SQUARE_METERS.defaultMessage,
      }),
    },
    {
      id: "bisou",
      title: intl.formatMessage({
        id: TRANSLATION.BISOU.id,
        defaultMessage: TRANSLATION.BISOU.defaultMessage,
      }),
    },
    {
      id: "acre",
      title: intl.formatMessage({
        id: TRANSLATION.ACRE.id,
        defaultMessage: TRANSLATION.ACRE.defaultMessage,
      }),
    },
  ];
  return currencies;
};

export const getUnits = (intl: IntlShape) => {
  const currencies: DropdownOptionModel[] = [
    {
      id: "squareMters",
      title: intl.formatMessage({
        id: TRANSLATION.SQUARE_METERS.id,
        defaultMessage: TRANSLATION.SQUARE_METERS.defaultMessage,
      }),
    },
    {
      id: "bisou",
      title: intl.formatMessage({
        id: TRANSLATION.BISOU.id,
        defaultMessage: TRANSLATION.BISOU.defaultMessage,
      }),
    },
    {
      id: "acre",
      title: intl.formatMessage({
        id: TRANSLATION.ACRE.id,
        defaultMessage: TRANSLATION.ACRE.defaultMessage,
      }),
    },
  ];
  return currencies;
};

export const PHONE_NUMBER_REGULAR_EXPRESSION =
  /^0(20|26|30|40|50|60|70|71|72|73|74|75|76|77|78|79)\d{7}$/;
