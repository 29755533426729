/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import clsx from "clsx";
import Button from "../components/shared/button";
import CloseIcon from "../components/icons/CloseIcon";

type Props = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactElement;
  className?: string;
  showCloseBtn?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  open,
  onClose,
  className,
  showCloseBtn = true,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" dir="rtl" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-30 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full  justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative transform overflow-hidden rounded-3xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all md:my-8 w-full md:p-6",
                  className
                )}
              >
                <div className="relative">
                  {showCloseBtn && (
                    <Button
                      onClick={onClose}
                      className="absolute -top-5 -start-4"
                      theme="label"
                      icon={<CloseIcon className="h-4 text-gray-500" />}
                    ></Button>
                  )}
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
