import React, {
  useState,
  Fragment,
  useEffect,
  ReactElement,
  ChangeEvent,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
// import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { DropdownOptionModel } from "../../model/DropdownOption";
import InputDropdownOption, { InputDropdownLabel } from "./DropdownOption";
import ChevronIcon from "../icons/ChevronIcon";
import { Typo } from "./typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
type Props = {
  iconType?: "none" | "image" | "icon" | "onlineIndicatro";
  position?: "relative" | "absolute";
  prefix?: string | ReactElement;
  suffex?: string | ReactElement;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  hasSearch?: boolean;
  options: Array<DropdownOptionModel>;
  defaultValue?: DropdownOptionModel;
  onChange?: (option: DropdownOptionModel) => void;
  searchStyle?: string;
  [x: string]: any;
};

const Dropdown: React.FC<Props> = (props) => {
  const {
    label = "",
    hasSearch = false,
    placeholder = "",
    position = "absolute",
    prefix,
    suffex,
    options = [],
    defaultValue,
    onChange,
    iconType = "none",
    disabled = false,
    searchStyle,
  } = props;
  const [selected, setSelected] = useState<DropdownOptionModel | undefined>(
    undefined
  );
  const [query, setQuery] = useState("");
  const dropdownOptions =
    query === ""
      ? options
      : options.filter((option: DropdownOptionModel) =>
          option.title
            .toLowerCase()
            ?.replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  useEffect(() => {
    if (defaultValue) {
      dropdownOptions.map((option) =>
        defaultValue.id === option.id ? setSelected(option) : ""
      );
    }
  }, [defaultValue, dropdownOptions]);

  return (
    <div className="flex flex-col gap-y-1.5 w-full">
      {label ? (
        <Typo.heading4
          fontWeight="font-normal"
          className="text-gray-700 text-start"
        >
          {label}
        </Typo.heading4>
      ) : (
        ""
      )}
      <Combobox value={selected} disabled={disabled} onChange={onChange}>
        {({ open }) => (
          <div className="relative">
            <div
              className={clsx(
                "relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-xs border border-gray-300 focus:ring-4 focus:ring-gray-100",
                { " ring-4 ring-gray-100": open }
              )}
            >
              {hasSearch ? (
                <div
                  className={clsx(
                    " flex items-center px-3.5 h-11",
                    searchStyle ? searchStyle : "h-11"
                  )}
                >
                  <Combobox.Input
                    placeholder={placeholder}
                    className="w-full border-none py-3 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={(option: DropdownOptionModel) =>
                      option?.title
                    }
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setQuery(event.target.value)
                    }
                  />
                </div>
              ) : (
                <Combobox.Button className="flex w-full py-3 justify-between whitespace-nowrap items-center px-3 group">
                  <InputDropdownLabel
                    prefix={prefix}
                    placeholder={placeholder}
                    value={selected}
                    iconType={iconType}
                    className=" text-gray-900"
                  ></InputDropdownLabel>

                  {suffex ? (
                    suffex
                  ) : (
                    <ChevronIcon
                      className={clsx("h-4 w-4 text-gray-500", {
                        " rotate-180 ": open,
                      })}
                    ></ChevronIcon>
                  )}
                </Combobox.Button>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                className={`${position} min-w-[150px] z-30 mt-2 w-full overflow-y-auto overflow-x-hidden py-1 max-h-60 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {dropdownOptions?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    <Typo.heading5
                      fontWeight="font-normal"
                      className="text-gray-500"
                    >
                      <FormattedMessage
                        id={TRANSLATION.NOTHING_FOUND.id}
                        defaultMessage={
                          TRANSLATION.NOTHING_FOUND.defaultMessage
                        }
                      />
                    </Typo.heading5>
                  </div>
                ) : (
                  <div>
                    {dropdownOptions?.map((option) => (
                      <Combobox.Option
                        key={option.id}
                        className={({ active }) =>
                          `relative cursor-pointer h-11  disabled:cursor-not-allowed select-none ${
                            active ? " text-gray-900" : "text-gray-700"
                          }`
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <InputDropdownOption
                            iconType={iconType}
                            key={option.id}
                            value={option}
                            selected={selected}
                          ></InputDropdownOption>
                        )}
                      </Combobox.Option>
                    ))}
                  </div>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default Dropdown;
