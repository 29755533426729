import { Typo } from "../../components/shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import PrivacyPolicyItem from "../shared/PrivacyPolicyItem";
const AboutUsMobile = () => {
  return (
    <div className="w-full">
      <div className="max-w-5xl mx-auto p-4 sm:p-6 md:p-8 lg:p-10">
        <Typo.headingD fontWeight="font-bold">
          <FormattedMessage
            id={TRANSLATION.PRIVACY_POLICY.id}
            defaultMessage={TRANSLATION.PRIVACY_POLICY_TITLE.defaultMessage}
          />
        </Typo.headingD>
        <Typo.heading3 className="text-gray-900 mt-4">
          <FormattedMessage
            id={TRANSLATION.PRIVACY_POLICY_DESCRIPTION.id}
            defaultMessage={
              TRANSLATION.PRIVACY_POLICY_DESCRIPTION.defaultMessage
            }
          />
        </Typo.heading3>

        <div className="space-y-8 mt-8">
          <PrivacyPolicyItem
            title={TRANSLATION.SECURITY_IN_DEAL_TITLE}
            description={TRANSLATION.SECURITY_IN_DEAL_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.CLEAR_ACCESSBILITY_TITLE}
            description={TRANSLATION.CLEAR_ACCESSBILITY_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.CUSTOMER_SUPPORT_TITLE}
            description={TRANSLATION.CUSTOMER_SUPPORT_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.EASY_SEARCH_TITLE}
            description={TRANSLATION.EASY_SEARCH_DESCRIPTION}
          />

          <PrivacyPolicyItem
            title={TRANSLATION.FOCUS_CUSTOMER_NEED_TITLE}
            description={TRANSLATION.FOCUS_CUSTOMER_NEED_DESCRIPTION}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsMobile;
