import { FormattedMessage } from "react-intl";
import { Typo } from "../../components/shared/typo";
import PrivacyPolicyItem from "./PrivacyPolicyItem";
import { TRANSLATION } from "../../static/translation";
type Props = {
  className?: string;
};
const PrivacyPolicyContent: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <div className="w-full">
      <div className="max-w-5xl mx-auto p-4 sm:p-6 md:p-8 lg:p-10">
        <Typo.headingD fontWeight="font-bold">
          <FormattedMessage
            id={TRANSLATION.PRIVACY_POLICY.id}
            defaultMessage={TRANSLATION.PRIVACY_POLICY_TITLE.defaultMessage}
          />
        </Typo.headingD>
        <Typo.heading3 className="text-gray-900 mt-4">
          <FormattedMessage
            id={TRANSLATION.PRIVACY_POLICY_DESCRIPTION.id}
            defaultMessage={
              TRANSLATION.PRIVACY_POLICY_DESCRIPTION.defaultMessage
            }
          />
        </Typo.heading3>

        <div className="space-y-8 mt-8">
          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_COLLECT_DATA}
            description={TRANSLATION.PRIVACY_POLICY_COLLECT_DATA_DESCRIPTION}
            items={[
              TRANSLATION.PRIVACY_POLICY_PERSONAL_INFO,
              TRANSLATION.PRIVACY_POLICY_PROPERTY_PREFERENCES,
              TRANSLATION.PRIVACY_POLICY_LOCATION_DATA,
              TRANSLATION.PRIVACY_POLICY_COOKIES_ANALYTICS,
            ]}
          />

          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_USE_DATA}
            description={TRANSLATION.PRIVACY_POLICY_USE_DATA_DESCRIPTION}
            items={[
              TRANSLATION.PRIVACY_POLICY_PROVIDE_LISTINGS,
              TRANSLATION.PRIVACY_POLICY_IMPROVE_SERVICES,
              TRANSLATION.PRIVACY_POLICY_COMMUNICATE_USERS,
              TRANSLATION.PRIVACY_POLICY_LEGAL_OBLIGATIONS,
            ]}
          />

          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_LOG_DATA}
            description={TRANSLATION.PRIVACY_POLICY_LOG_DATA_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_SECURITY_THREATS}
            description={TRANSLATION.PRIVACY_POLICY_SECURITY_DESCRIPTION}
          />

          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_CHANGE_POLICY}
            description={TRANSLATION.PRIVACY_POLICY_CHANGE_DESCRIPTION}
          />

          <PrivacyPolicyItem
            title={TRANSLATION.PRIVACY_POLICY_CONTACT_US}
            description={TRANSLATION.PRIVACY_POLICY_CONTACT_DETAILS}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
