import EmailFillIcon from "../../components/icons/EmailFillIcon";
import PhoneIcon from "../../components/icons/PhoneIcon";
import WhatsAppIcon from "../../components/icons/WhatsappIcon";
import { Typo } from "../../components/shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import PrivacyPolicyItem from "../shared/PrivacyPolicyItem";
const ContactMobile = () => {
  const contacts = [
    {
      title: TRANSLATION.PHONE,
      icon: <PhoneIcon className="h-7 w-5 flex-none text-primary-600" />,
      contact: "+93766111134",
    },
    {
      title: TRANSLATION.WHATSAPP,
      icon: <WhatsAppIcon className="h-7 w-5 flex-none text-primary-600" />,
      contact: "+93783111134",
    },
    {
      title: TRANSLATION.EMAIL,
      icon: <EmailFillIcon className="h-7 w-5 flex-none text-primary-600" />,
      contact: "support@afghouse.com",
    },
  ];
  return (
    <div className="w-full">
      <div className="max-w-5xl mx-auto p-4 sm:p-6 md:p-8 lg:p-10">
        <Typo.headingD fontWeight="font-bold">
          <FormattedMessage
            id={TRANSLATION.CONTACT_US_TITLE.id}
            defaultMessage={TRANSLATION.CONTACT_US_TITLE.defaultMessage}
          />
        </Typo.headingD>
        <Typo.heading3 className="text-gray-900 mt-4">
          <FormattedMessage
            id={TRANSLATION.CONTACT_US_DESCRIPTION.id}
            defaultMessage={TRANSLATION.CONTACT_US_DESCRIPTION.defaultMessage}
          />
        </Typo.heading3>

        <div className="space-y-8 mt-8">
          <PrivacyPolicyItem
            title={TRANSLATION.CONTACT_SUPPORT_TITLE}
            description={TRANSLATION.CONTACT_SUPPORT_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.CONTACT_DEAL_GUIDE_TITLE}
            description={TRANSLATION.CONTACT_DEAL_GUIDE_DESCRIPTION}
          />
          <PrivacyPolicyItem
            title={TRANSLATION.CONTACT_TECKHNICAL_SUPPORT_TITLE}
            description={TRANSLATION.CUSTOMER_SUPPORT_DESCRIPTION}
          />
        </div>
        <div className="container mx-auto mt-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {contacts?.map((contact) => {
              return (
                <div
                  key={contact.contact}
                  className="bg-white rounded-lg shadow-md gap-4 p-6 flex items-center"
                >
                  {contact.icon}
                  <div>
                    <h3 className="text-xl font-semibold"></h3>
                    <Typo.headingA>
                      <FormattedMessage
                        id={contact.title.id}
                        defaultMessage={contact.title.defaultMessage}
                      />
                    </Typo.headingA>
                    <Typo.body2 className=" text-gray-600">
                      <span dir="ltr">{contact.contact}</span>
                    </Typo.body2>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMobile;
