import React from "react";
import { Typo } from "./typo";
import { DropdownOptionModel } from "../../model/DropdownOption";
import Button from "./button";
import clsx from "clsx";

type Props = {
  className?: string;
  selectedTab: DropdownOptionModel;
  onChange: (tab: DropdownOptionModel) => void;
  options: DropdownOptionModel[];
};

const HomeFilterTab: React.FC<Props> = (props) => {
  const { selectedTab, onChange, options } = props;
  return (
    <div className="flex rounded-t-xl cursor-pointer ">
      {options?.map((option, index) => {
        return (
          <Button
            key={option.id}
            rounded="rounded-none"
            onClick={() => onChange(option)}
            className={clsx(
              "py-4 px-8 hover:bg-primary-600 hover:text-white",
              selectedTab && selectedTab?.id === option.id
                ? "bg-primary-500 text-white"
                : "bg-white text-gray-300",
              {
                "rounded-ss-xl": index === 0,
                "rounded-se-xl": index === 2,
              }
            )}
          >
            <Typo.heading3 fontWeight="font-medium">
              {option.title}
            </Typo.heading3>
          </Button>
        );
      })}
    </div>
  );
};

export default HomeFilterTab;
