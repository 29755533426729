type Props = {
  className?: string;
  EnTitleColor?: string;
  PrTitleColor?: string;
};
const AHNameColumn = ({ className, EnTitleColor, PrTitleColor }: Props) => {
  console.log("kkkkkkk ", PrTitleColor);
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 184.8 43.07"
      className={className}
    >
      <g>
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M0,17.01L5.85,.45h4.01l5.86,16.56h-3.68l-1.11-3.37H4.66l-1.1,3.37H0ZM7.81,4.14l-2.19,6.65h4.4l-2.21-6.65Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M17.95,17.01V.45h11.78V3.39h-8.32v3.77h7.25v2.85h-7.25v7h-3.46Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M39.16,17.46c-2.43,0-4.34-.78-5.75-2.33-1.42-1.56-2.12-3.66-2.12-6.3s.73-4.85,2.2-6.45c1.48-1.59,3.47-2.38,5.98-2.38,2,0,3.66,.5,4.97,1.51,1.29,.99,2.05,2.35,2.28,4.08h-3.47c-.09-.72-.5-1.36-1.22-1.9s-1.51-.82-2.38-.82c-1.5,0-2.68,.52-3.53,1.57-.85,1.04-1.27,2.49-1.27,4.32s.41,3.32,1.22,4.3c.82,.98,2.01,1.46,3.57,1.46,1.34,0,2.38-.51,3.13-1.54,.41-.58,.71-1.26,.91-2.03v-.04h-3.9v-2.85h6.94v8.93h-2.3l-.34-2.14-.21,.27c-1.17,1.55-2.74,2.33-4.71,2.33Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M49.85,17.01V.45h3.46V6.65h6.47V.45h3.47V17.01h-3.47v-7.49h-6.47v7.49h-3.46Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M65.35,17.01L71.2,.45h4.01l5.86,16.56h-3.68l-1.11-3.37h-6.28l-1.1,3.37h-3.56Zm7.81-12.87l-2.19,6.65h4.4l-2.21-6.65Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M86.36,17.01h-3.22V.45h3.62l6.64,11.61V.45h3.24V17.01h-3.44l-6.83-11.92v11.92Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M105.46,17.01V.45h3.46V6.65h6.47V.45h3.47V17.01h-3.47v-7.49h-6.47v7.49h-3.46Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M129.46,17.46c-2.44,0-4.38-.78-5.81-2.33-1.43-1.54-2.15-3.65-2.15-6.3s.72-4.86,2.15-6.45c1.43-1.59,3.37-2.38,5.81-2.38s4.38,.79,5.81,2.38c1.43,1.59,2.15,3.74,2.15,6.45s-.72,4.76-2.15,6.3c-1.44,1.55-3.38,2.33-5.81,2.33Zm0-2.94c1.41,0,2.51-.5,3.3-1.51,.8-1.01,1.2-2.41,1.2-4.21s-.4-3.28-1.2-4.31c-.79-1.04-1.89-1.55-3.3-1.55s-2.51,.52-3.31,1.55c-.79,1.04-1.19,2.48-1.19,4.31s.4,3.19,1.19,4.21c.79,1.01,1.89,1.51,3.31,1.51Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M153.46,10.77c0,2.37-.52,4.08-1.57,5.13s-2.75,1.57-5.11,1.57-3.95-.55-5.04-1.64c-1.09-1.09-1.64-2.78-1.65-5.05V.45h3.46V10.89c0,2.37,1.08,3.56,3.24,3.56,1.16,0,1.98-.27,2.47-.82,.49-.55,.74-1.47,.74-2.74V.45h3.47V10.77Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M165.83,5.42c-.03-.4-.13-.76-.31-1.07-.18-.32-.42-.59-.73-.81-.3-.22-.66-.39-1.08-.51-.42-.12-.88-.18-1.38-.18-.89,0-1.58,.19-2.07,.58s-.74,.93-.74,1.62c0,.5,.26,.88,.78,1.15,.25,.14,.65,.28,1.18,.44,.54,.16,1.23,.33,2.06,.52,2.41,.53,4.02,1.15,4.84,1.86,.81,.7,1.22,1.82,1.22,3.37s-.61,2.79-1.84,3.7c-1.23,.91-2.88,1.37-4.97,1.37-1.05,0-1.99-.12-2.82-.37-.82-.25-1.52-.61-2.1-1.09s-1.02-1.06-1.35-1.75c-.32-.69-.5-1.48-.54-2.38h3.33c.03,.89,.35,1.57,.95,2.04,.6,.47,1.44,.71,2.52,.71s1.98-.18,2.6-.54c.62-.36,.93-.85,.93-1.48s-.22-1.08-.67-1.37c-.22-.15-.55-.3-1.02-.45-.46-.15-1.06-.31-1.78-.47-2.62-.58-4.37-1.21-5.27-1.9-.88-.67-1.32-1.73-1.32-3.16,0-1.6,.58-2.87,1.75-3.82,1.17-.95,2.75-1.42,4.73-1.42,1.86,0,3.36,.49,4.52,1.48,1.17,.97,1.81,2.28,1.94,3.95h-3.37Z"
        />
        <path
          className={`fill-${EnTitleColor ? EnTitleColor : "primary-600"}`}
          d="M172.23,17.01V.45h12.18V3.39h-8.73v3.52h8.09v2.85h-8.09v4.31h9.11v2.94h-12.57Z"
        />
      </g>
      <g>
        <rect
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          x="181"
          y="28.31"
          width="3.45"
          height="14.76"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M136.21,28.06v15.01h-21.67c-4.14,0-7.49-3.36-7.49-7.49v-.02c0-4.14,3.36-7.49,7.49-7.49h4.53v3.37h-4.73c-2.15,0-3.9,1.74-3.9,3.89,0,1.08,.44,2.06,1.15,2.76,.7,.7,1.67,1.14,2.75,1.14h18.5v-11.16h3.37Z"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M29.16,27.99v15.01H7.49c-4.14,0-7.49-3.36-7.49-7.49v-.02c0-4.14,3.36-7.49,7.49-7.49h4.53v3.37H7.3c-2.15,0-3.9,1.74-3.9,3.89,0,1.08,.44,2.06,1.15,2.76,.7,.7,1.67,1.14,2.75,1.14H25.8v-11.16h3.37Z"
        />
        <circle
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          cx="156.11"
          cy="25.65"
          r="2.13"
        />
        <circle
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          cx="172.06"
          cy="25.65"
          r="2.13"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M171.84,28.31c-3.99,0-7.22,3.23-7.22,7.23v3.64h-2.47c.47-.96,.74-2.04,.74-3.18v-7.53h-14.44v7.53c0,1.14,.27,2.22,.74,3.18h-6.95v-11.11h-3.86v15h37.06s3.64,.01,3.64,.01v-7.53c0-3.99-3.24-7.23-7.23-7.23Zm-12.15,7.62c0,1.05-.43,2-1.12,2.69-.21,.21-.44,.4-.71,.55-.58,.36-1.25,.56-1.98,.56s-1.4-.2-1.97-.56c-1.1-.66-1.83-1.87-1.83-3.25v-4.02h7.62v4.02Zm15.74,3.25h-7.6v-3.58c0-1.05,.43-2,1.11-2.68,.69-.69,1.64-1.12,2.69-1.12,2.1,0,3.8,1.7,3.8,3.8v3.58Z"
        />
        <circle
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          cx="125.64"
          cy="29.91"
          r="2.13"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M64.3,35.49c0,2.07-.84,3.93-2.19,5.29-1.36,1.36-3.23,2.19-5.29,2.19h-14.8v-3.26h14.64c1.13,0,2.14-.46,2.87-1.19,.73-.74,1.19-1.75,1.19-2.87,0-2.24-1.81-4.06-4.06-4.06h-11.12v3.1h7.88v3.45h-11.4v-10.13h14.8c4.13,0,7.47,3.35,7.47,7.47Z"
        />
        <rect
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          x="30.83"
          y="28.07"
          width="3.56"
          height="14.93"
        />
        <rect
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          x="35.75"
          y="28.07"
          width="3.56"
          height="14.93"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M103.64,35.57c0,2.06-.83,3.93-2.19,5.28-1.36,1.35-3.22,2.19-5.29,2.19h-29.71v-14.93h3.56v11.26h4.21c.13,.02,.27,.03,.41,.03h.12v-.03h2.34c-.65-1.1-1.02-2.38-1.02-3.75v-.12c0-1.28,.32-2.48,.89-3.53,1.25-2.32,3.71-3.89,6.53-3.89h3.05c4.11,0,7.44,3.33,7.44,7.44,0,.68-.09,1.35-.28,1.97h-4.06c.29-.53,.46-1.14,.46-1.78,0-1.02-.41-1.95-1.09-2.62-.67-.67-1.59-1.09-2.62-1.09h-2.77c-2.05,0-3.7,1.66-3.7,3.7,0,1.02,.42,1.95,1.09,2.62s1.59,1.09,2.62,1.09h10.85c1.57,0,3-.63,4.03-1.66,.31-.31,.58-.64,.81-1.01,.1-.33,.15-.67,.15-1.03v-.32c0-.86-.3-1.66-.78-2.28-.19-.24-.4-.46-.63-.63l-7.16-7.16,2.77-2.78,7.78,7.78c.98,.97,1.68,2.21,1.99,3.6,.13,.53,.2,1.1,.2,1.67Z"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M82.11,39.37v.03h-.12c-.14,0-.28,0-.41-.03h.53Z"
        />
        <path
          className={`fill-${PrTitleColor ? PrTitleColor : "secondary-600"}`}
          d="M82.11,39.37v.03h-.12c-.14,0-.28,0-.41-.03h.53Z"
        />
      </g>
    </svg>
  );
};

export default AHNameColumn;
